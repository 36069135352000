// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
WebFont.load({
    google: { // add &display=swap to end of every font family
        families: ['Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap']
    },
    //,
    // typekit: {
    // id: 'XXXX'
    // },
    active: function () { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true
    }
});
// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
// require('magnific-popup/dist/jquery.magnific-popup.min');
// require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
// require('slick-slider/slick/slick.min');

//Load Modules
// require('./modules/accordion');
require('./modules/animatein');
// require('./modules/bannerVideo');
require('./modules/formInputs');
// require('./modules/localVideoBlock');
require('./modules/menu');
// require('./modules/modal');
// require('./modules/scrollTo');
// require('./modules/searchToggle');
// require('./modules/shareSocial');
// require('./modules/sideMenu');
// require('./modules/tabs');
// require('./modules/alert');

$(function () {
    var header = $('.header');
    var headerNavHeight = $('.header').outerHeight();
    var headerScrollTrigger = 100;
    var navigation = $('.navwrap');

    if (screen.width < 992) {
        $(window).on('scroll', function () {
            var st = window.pageYOffset || $(document).scrollTop();
            if (st > headerScrollTrigger) {
                navigation.addClass('__up');
                header.addClass('__up');
            } else {
                if ($(document).scrollTop() < headerScrollTrigger) {
                    navigation.removeClass('__up');
                    header.removeClass('__up');
                }
            }
        });
    }

    if (screen.width < 768) {
        $('.footerNavTrigger').click(function () {
            $(this).next('ul').slideToggle();
            $(this).toggleClass('__active');
        });
    }

    if ($('.footer').has('.footer-podcast').length) {
        // It has that element
        $('.footer').addClass('__podcast');
        $('footer').addClass('__hasPodcast');
    }
});

/* animations */
$(window).on("load", function () {

    $('.__animate').animateIn({
        offset: 80,
        modifier: '__animatein'
    });

    $('.__animatefade').animateIn({
        offset: 50,
        modifier: '__animatein'
    });
});