/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radiobuttons')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');

/* checkboxes */
$(document).on('change', '.__checkbox input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');
$(document).on('change', '.__checkboxes input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkboxes input:checked')
    .parent()
    .addClass('__checked');

/* focus styling */
$(document).ready(function () {
    $('.field_wrap.__radiobuttons input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input, .field_wrap.__checkboxes input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });

    $('.card *').focus(function () {
        $(this)
            .parents('.card')
            .addClass('__active');
    }).blur(function () {
        $(this)
            .parents('.card')
            .removeClass('__active');
    });

    // Select with no search
    $('.__select.__nosearch select').each(function () {
        var selectId = $(this).parent();
        $(this).select2({
            containerCssClass: 'select2-css',
            minimumResultsForSearch: Infinity,
        })
    });
});


